<template>
    <div id="mobile_header" v-show="isNavDisplayable && !forceHide">
        <header>
            <b-navbar toggleable="lg">
                <b-navbar-brand href="#"><groomy-logo/></b-navbar-brand>
                <h3 v-if="title !== ''">{{ title }}</h3>
                <b-button class="nav-link nav-light" @click="onQuickNavClick" v-if="!isNavOpen"><font-awesome-icon :icon="['far', 'stream']" /> {{ $t('header.menu') }}</b-button>
                <b-button class="nav-link nav-light" @click="onQuickNavClick" v-else><font-awesome-icon :icon="['fal', 'times']" /> {{ $t('header.fermer') }}</b-button>
            </b-navbar>
        </header>
        <div id="mobile_nav" v-show="isNavOpen">
            <div class="row mt-lg-5">
                <div v-for="menu in menus" class="col-6 mb-4" :key="menu.title">
                    <router-link
                        active-class="menuActive"
                        class="h-100 item-menu"
                        :to="menu.to"
                    >
                        <span @click="checkActive"><span>
                        <i v-b-tooltip.right :title="$t(menu.title)">
                            <font-awesome-icon :icon="menu.icon" />
                        </i></span>{{ $t(menu.title) }}</span>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center logOut">
                    <a href="#" v-on:click.prevent="logout">
                    {{ $t('header.logout') }}
                        <i v-b-tooltip.right title="Déconnexion">
                            <font-awesome-icon :icon="['fal', 'sign-out-alt']" />
                        </i> 
                    </a>
                </div>
            </div>
        </div>
        <!-- <div class="menu-container">
            <div class="add" @click="onQuickNavClick">
                <font-awesome-icon :icon="['fal', 'bars']" />
                <font-awesome-icon :icon="['fal', 'times']" />
            </div>
            <div class="menu container-fluid px-2 pt-3">
                <div class="row align-items-center no-gutters">
                    <div class="col-auto">
                        <a id="btn_menu">
                            <span v-if="subDomainName">
                                <custom-menu-logo />
                            </span>
                            <span v-else>
                                <font-awesome-icon :icon="['fal', 'bars']" />
                            </span>
                        </a>
                    </div>
                    <div
						class="col-auto mr-auto"
						@click="openSearch"
					>
                        <a><font-awesome-icon :icon="['fal', 'search']" /></a>
                    </div>
                    <div class="col-auto">
                        <a>
                            <font-awesome-icon
                                v-if="isNavFooterOpen"
                                :icon="['fal', 'times-square']"
                                @click="$store.commit('nav/closeFooter')"
                            />
                            <font-awesome-icon
                                v-else
                                :icon="['fal', 'user-cog']"
                                @click="$store.commit('nav/openFooter')"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import { EventBus } from 'EventBus'
import { getSubDomainName } from "@/assets/js/common.js"
import MenusReg from '@/assets/js/menus'
import Menus from '@/assets/js/menus'
import Navigation from '@/mixins/Navigation'

export default {
    name: 'NavMobile',
    mixins: [Navigation],
    props: {
        fromCa: { type: Boolean, default: false }
    },
    components: {
        customMenuLogo: () => {
            const subDomain = getSubDomainName()
            return import(`@/components/Logos/custom/${subDomain}/LogoMenu_111`)    
        }
    },
    data() {
        return {
			menus: [],
			tablesSelected: [],
            title: '',
            events_tab: {
				'User::AccessRightsReloaded': this.reloadMenus,
				'NavMobile::ShowNav': (identifier) => {
					if(!this.tablesSelected.includes(identifier)) {
						this.tablesSelected.push(identifier)
					}
				},
				'NavMobile::HideNav': (identifier) => {
					const idx = this.tablesSelected.indexOf(identifier)
					if (idx !== -1) {
						this.tablesSelected.splice(idx, 1)
					}
				},
            }
        }
    },
    computed: {
		forceHide() {
			return this.tablesSelected.length > 0
		},
		isNavDisplayable() {
			const parent_rights = this.$route.matched.find(record => record.meta.canDisplayNavMobile !== undefined)
			return parent_rights !== undefined ? parent_rights.meta.canDisplayNavMobile : true
		},
        isNavOpen() {
            return this.$store.state.nav.isOpen
        },
        isNavFooterOpen() {
            return this.$store.state.nav.isFooterOpen
        },
        isNavNotificationsOpen() {
            return this.$store.state.nav.isNotificationsOpen
        }
    },
    watch: {
        '$route'() {
            this.$store.commit('nav/close');
            this.$store.commit('nav/closeAdd');
            this.$store.commit('nav/closeNotifications');
            this.setTitle()
        }
    },
    created() {
		MenusReg.setInstance(this)
        this.reloadMenus()
    },
    methods: {
        reloadMenus() {
            const menus = Menus.user(this.$route.name)
            this.menus = menus.filter(menu => menu.mobile)
        },
        onQuickNavClick() {
            this.isNavOpen ? this.$store.commit('nav/close') : this.$store.commit('nav/open')
        },
		openSearch() {
			this.$store.commit('nav/open')
			EventBus.$emit('SearchInput::focus')
		},
        async logout() {
            await this.delogUser()
        },
        checkActive(e) {
            if(!e.path) return false
            let active = e.path.filter(path => path.classList && path.classList.contains('menuActive'))
            if(active.length > 0) {
                this.onQuickNavClick()
            }
        },
        setTitle() {
            this.title = ''
            const titles_icon = {
                'paxHome': null,
                'pax': null,
                'paxReverse': null
            }
            if(Object.prototype.hasOwnProperty.call(titles_icon, this.$route.name)) {
                this.title = this.getTrad('header.title.'+this.$route.name)
            }
        }
    }
}
</script>
